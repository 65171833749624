import BasePlugin from '../BasePlugin'

export default class CopyAcceptanceForAccountingCommand extends BasePlugin {
  async execute () {
    let investmentBudgetId = this.context.getModel().id

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/InvestmentBudget/CopyAcceptanceForAccountingCommand`,
      { 'investmentBudgetId': investmentBudgetId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getDashboardComponents()['component_00472009-be9a-484e-aa78-d471983d7ac5'][0].loadData()
      this.context.$message({
        type: 'info',
        message: 'Данные по принятию к учету обновлены'
      })
      done()
    })
  }
}
